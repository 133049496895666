import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../lib/axiosInstance';
// @mui
import { Container, Typography, Card, CardContent, Stack, Button } from '@mui/material';
import Order from '../components/Order';
import { ORDER_STATUS, ORDER_ACTIONS } from '../constants';
import useNavigateWrapper from '../hooks/useNavigateWrapper';
// ----------------------------------------------------------------------

export default function OrderPage(props) {
  const { orderId } = useParams();
  const [order, setOrder] = useState();
  const { orderDetails } = order || [];
  const { refresh } = useNavigateWrapper();

  useEffect(() => {
    axiosInstance
      .get(`/v1/orders/${orderId}`)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [orderId]);

  async function acceptOrder(orderId) {
    try {
      await axiosInstance.post('/v1/orders/manipulate', {
        order: orderId,
        action: ORDER_ACTIONS.accept
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  async function declineOrder(orderId) {
    try {
      await axiosInstance.post('/v1/orders/manipulate', {
        order: orderId,
        action: ORDER_ACTIONS.decline
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  async function executeOrder(orderId) {
    try {
      await axiosInstance.post('/v1/orders/manipulate', {
        order: orderId,
        action: ORDER_ACTIONS.execute
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  if (!order) {
    return <div>Loading...</div>
  }

  return (
    <Container sx={{ paddingBottom: '140px' }}>
      <Typography variant="h5" align="center" sx={{ padding: '30px' }}>
        Детали Заказа
      </Typography>
      <Card sx={{ position: 'relative' }}>
        <CardContent>
          <Order order={order} orderDetails={orderDetails} />
        </CardContent>
      </Card>
      <Stack
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '10%',
          width: '80%',
          paddingBottom: '10px',
          backgroundColor: 'white'
        }}
        gap={1}
      >
        {order.status === ORDER_STATUS.pending && (
          <>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => acceptOrder(orderId)}
              sx={{
                boxShadow: 'none',
              }}
            >
              <Typography fontWeight={700}>Принять</Typography>
            </Button>
            <Button color='error' variant="outlined" fullWidth onClick={() => declineOrder(orderId)}>
              <Typography fontWeight={700}>Отклонить</Typography>
            </Button>
          </>
        )}
        {order.status === ORDER_STATUS.accepted && (
          <Button
            variant='outlined'
            color='success'
            fullWidth
            onClick={() => executeOrder(orderId)}
            sx={{
              boxShadow: 'none',
            }}
          >
            <Typography fontWeight={700}>Завершить заказ</Typography>
          </Button>
        )}
      </Stack>
    </Container>
  );
}
