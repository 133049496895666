import { createContext } from 'react';

const accessTokenKey = 'accessToken';
const refreshTokenKey = 'refreshToken';

const AuthContext = createContext();

function AuthProvider(props) {
  const login = (accessToken, refreshToken) => {
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(refreshTokenKey, refreshToken);
  };

  const logout = () => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
  };

  const isLoggedIn = () => {
    return localStorage.getItem(accessTokenKey) && localStorage.getItem(refreshTokenKey);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoggedIn,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
