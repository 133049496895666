import { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, TextField, Card, Alert, Typography } from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import axiosInstance from '../lib/axiosInstance';
import useNavigateWrapper from '../hooks/useNavigateWrapper';

export default function LoginForm() {
  const { goToTables } = useNavigateWrapper();
  const [errorText, setErrorText] = useState('');
  const { login } = useContext(AuthContext);
  return (
    <Card
      sx={{
        width: '50%',
        margin: 'auto',
        padding: '4px',
      }}
    >
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={async (values, actions) => {
          try {
            const { data } = await axiosInstance.post('/v1/auth/login', {
              username: values.username,
              password: values.password,
              deviceToken: 'web'
            });
            login(data.tokens.access.token, data.tokens.refresh.token);
            goToTables();
          } catch (error) {
            console.log(error);
            if (error.response.status) {
              setErrorText('Логин или пароль не правильный');
              return;
            }
          }

          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              name="username"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Username"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <Field
              name="password"
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label="Password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              fullWidth
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
      {errorText && (
        <Alert severity="error">
          <Typography>{errorText}</Typography>
        </Alert>
      )}
    </Card>
  );
}
