import { useEffect, useState, useCallback, useContext } from 'react';
import useNavigateWrapper from '../hooks/useNavigateWrapper';
import axiosInstance from '../lib/axiosInstance';
import { AuthContext} from '../context/AuthContext';
// @mui
import { Container, Typography, Stack, ButtonBase, Card, CardContent, Button, Box , Modal, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { TABLE_STATUS, TABLE_STATUSES_COLORS, TABLE_STATUSES_RUSSIAN } from '../constants';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

export default function Tables() {
  const [tables, setTables] = useState([]);
  const [waiterCalls, setWaiterCalls] = useState([]);
  const [waiterCallsModalOpen, setWailterCallsModalOpen] = useState(false);
  const context = useContext(AuthContext);

  const getApiData = useCallback(async () => {
    try {
      const tablesRes = await axiosInstance.get(`/v1/tables`);
      setTables(tablesRes.data);
      const waiterCallsRes = await axiosInstance.get(`/v1/tables/waiter-call`);
      setWaiterCalls(waiterCallsRes.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getApiData();

    const interval = setInterval(() => {
      getApiData();
    }, 15000);

    return () => clearInterval(interval);
  }, [getApiData]);

  const { goToTable, goToLogin } = useNavigateWrapper();

  function openWaiterCallsModal() {
    setWailterCallsModalOpen(true);
  }

  function closeWaiterCallsModal() {
    setWailterCallsModalOpen(false);
  }

  async function deleteWaiterCall(tableId) {
    try {
      await axiosInstance.post('/v1/tables/waiter-call', {
        table: tableId
      });

      setWaiterCalls(waiterCalls.filter(callData => {
        return callData.table._id !== tableId;
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function onLogout() {
    try {
      await axiosInstance.post('/v1/auth/logout');

      context.logout();

      goToLogin();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container sx={{ paddingBottom: '30px' }}>
      <Typography
        variant="h5"
        align="center"
        sx={{
          padding: '30px 10px 30px 10px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            textTransform: 'none',
            border: '1px solid #ed6c02',
            boxShadow: '0px 3px 5px rgba(0,0,0,0.3)'
          }}
          onClick={() => openWaiterCallsModal()}
        >
          <Box
            component="img"
            sx={{
              width: '46px',
              display: 'block',
              paddingRight: '10px'
            }}
            src={require('../icons/bell.png')}
          />
          <Typography fontSize={24} color='red' fontWeight={700}>{waiterCalls.length}</Typography>
        </Button>
        <IconButton onClick={onLogout} sx={{
          borderRadius: '10px',
          boxShadow: '0px 3px 5px rgba(0,0,0,0.3)',
          padding: '5px',
          color: 'gray'
        }}>
          <Iconify icon={'material-symbols:logout'} width={36} height={36} />
        </IconButton>
      </Typography>

      {tables.every(table => table.status === TABLE_STATUS.free) && (
        <Typography align="center" variant="h6" mt="60px">На данный момент в системе нет заказов по QR коду</Typography>
      )}

      <Stack
        gap={2}
      >
        {tables && tables.map((table, index) => {
          if (table.status === TABLE_STATUS.free) {
            return null;
          }
          return (
            <ButtonBase key={index} onClick={() => goToTable(table._id)}>
              <Card sx={{
                width: '90%',
                boxShadow: 'none',
                borderRadius: '16px',
                height: '120px',
                backgroundColor: `#${TABLE_STATUSES_COLORS[table.status]}`
              }}>
                <CardContent>
                  <Typography variant='h6'>
                    Стол: {table.number}
                  </Typography>
                  <Typography>
                    Статус: {TABLE_STATUSES_RUSSIAN[table.status]}
                  </Typography>
                  {table.pendingOrdersCount !== 0 && (
                    <Typography>
                      Новые заказы: {table.pendingOrdersCount}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </ButtonBase>
          )
        })}
      </Stack>
      <Modal
        open={waiterCallsModalOpen}
        onClose={() => closeWaiterCallsModal()}
      >
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '16px'
          }}
        >
          <h3>Вызовы официантов</h3>
          <List sx={{
            maxHeight: '300px',
            overflowY: 'auto'
          }}>
            {waiterCalls.map((callData, index) => (
              <ListItem key={index}>
                <ListItemText primary={`Стол #${callData.table.number}`} />
                <ListItemSecondaryAction>
                  <Button
                    variant="outlined"
                    color='error'
                    sx={{
                      textTransform: 'none',
                    }}
                    onClick={() => deleteWaiterCall(callData.table._id)}
                  >
                    <Typography fontSize={16}>Удалить</Typography>
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button color='info' variant="outlined" fullWidth onClick={() => closeWaiterCallsModal()}>
            <Typography fontWeight={700}>Закрыть</Typography>
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}
