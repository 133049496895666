import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWrapper from '../hooks/useNavigateWrapper';
import axiosInstance from '../lib/axiosInstance';
// @mui
import { Typography, Stack, Button, Modal, Box, Alert, TextField, MenuItem, Menu, Divider } from '@mui/material';
import { TABLE_ACTIONS, TABLE_STATUS, ORDER_FINAL_STATUSES } from '../constants';

// ----------------------------------------------------------------------

export default function TableActions(props) {
  const { orders } = props;
  const { tableId } = useParams();
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [freeTables, setFreeTables] = useState([]);
  const [targetTableId, setTargetTableId] = useState(null);
  const [showTargetTableAlert, setShowTargetTableAlert] = useState(false);
  const [showFinishAlert, setShowFinishAlert] = useState(false);

  useEffect(() => {
    if (!moveModalOpen) {
      return;
    }
    axiosInstance
      .get(`/v1/tables`)
      .then((res) => {
        const tables = res.data || [];
        const freeTablesTemp = tables.filter(tables => {
          if (tables.status === TABLE_STATUS.free) {
            return true;
          }

          return false;
        });
        setFreeTables(freeTablesTemp);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [moveModalOpen]);

  const { refresh } = useNavigateWrapper();

  function openBlockModal() {
    setBlockModalOpen(true);
  }

  function closeBlockModal() {
    setBlockModalOpen(false);
  }

  function openFinishModal() {
    setFinishModalOpen(true);
  }

  function closeFinishModal() {
    setFinishModalOpen(false);
  }

  function openMoveModal() {
    setMoveModalOpen(true);
  }

  function closeMoveModal() {
    setTargetTableId(null);
    setMoveModalOpen(false);
  }

  async function finishTable(tableId) {
    const allOrdersFinished = orders.every(order => {
      return ORDER_FINAL_STATUSES.includes(order.status);
    });

    if (!allOrdersFinished) {
      setShowFinishAlert(true);

      return;
    }
    try {
      await axiosInstance.post('/v1/tables/manipulate', {
        table: tableId,
        action: TABLE_ACTIONS.FINISH
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  async function blockTable(tableId) {
    try {
      await axiosInstance.post('/v1/tables/manipulate', {
        table: tableId,
        action: TABLE_ACTIONS.BLOCK
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  async function moveTable(tableId, targetTableId) {
    if (!targetTableId) {
      setShowTargetTableAlert(true);
      return;
    }
    try {
      await axiosInstance.post('/v1/tables/manipulate', {
        table: tableId,
        action: TABLE_ACTIONS.MOVE,
        newTable: targetTableId
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  }

  const handleTargetTableChange = (event) => {
    setTargetTableId(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} variant='outlined'>
        <Typography fontWeight={500} fontSize={14} ml='18px' mr='18px'>Действия</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem dense={true} onClick={() => openBlockModal(tableId)}>Заблокировать</MenuItem>
        <Divider variant="middle" />
        <MenuItem dense={true} onClick={() => openMoveModal()}>Пересадить</MenuItem>
        <Divider variant="middle" />
        <MenuItem dense={true} onClick={() => openFinishModal()}>Завершить</MenuItem>
      </Menu>
      <Modal
        open={blockModalOpen}
        onClose={() => closeBlockModal()}
      >
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '16px'
          }}
        >
          <h2>Потверждение</h2>
          <p>
            Вы точно хотите заблокировать автора заказа?
          </p>
          <Stack direction='row' gap={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => blockTable(tableId)}
                sx={{
                  boxShadow: 'none',
                }}
              >
                <Typography>Да</Typography>
              </Button>
              <Button color='error' variant="outlined" fullWidth onClick={() => closeBlockModal()}>
                <Typography>Закрыть</Typography>
              </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={finishModalOpen}
        onClose={() => closeFinishModal()}
      >
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '16px'
          }}
        >
          <h2>Потверждение</h2>
          <p>
            Вы точно завершить сеанс по столу?
          </p>
          {showFinishAlert && (
            <Alert severity="warning" sx={{
              width: '100%',
              marginBottom: '10px'
            }}>Необходимо, чтобы все заказы были завершены!</Alert>
          )}
          <Stack direction='row' gap={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => finishTable(tableId)}
                sx={{
                  boxShadow: 'none',
                }}
              >
                <Typography>Да</Typography>
              </Button>
              <Button color='error' variant="outlined" fullWidth onClick={() => closeFinishModal()}>
                <Typography>Закрыть</Typography>
              </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={moveModalOpen}
        onClose={() => closeMoveModal()}
      >
        <Box
          sx={{
            width: '80%',
            backgroundColor: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            borderRadius: '16px'
          }}
        >
          <h2>Потверждение</h2>
          <p>
            Вы точно хотите пересадить гостя?
          </p>
          {showTargetTableAlert && (
            <Alert severity="warning" sx={{
              width: '100%'
            }}>Пожалуйста выберите столик куда пересадить гостя</Alert>
          )}
          <TextField
            value={targetTableId}
            label="Стол"
            onChange={handleTargetTableChange}
            select
            sx={{
              width: '100%',
              marginBottom: '20px'
            }}
            size='small'
          >
            {freeTables.map((table, index) => {
              return <MenuItem value={table._id} key={index}>Стол #{table.number}</MenuItem>
            })}
          </TextField>
          
          <Stack direction='row' gap={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => moveTable(tableId, targetTableId)}
                sx={{
                  boxShadow: 'none',
                }}
              >
                <Typography>Да</Typography>
              </Button>
              <Button color='error' variant="outlined" fullWidth onClick={() => closeMoveModal()}>
                <Typography>Закрыть</Typography>
              </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
