import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
// @mui
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  Divider,
  Paper,
  Grid,
  Checkbox
} from '@mui/material';
import Iconify from './Iconify';

import { ORDER_STATUS_ICON, ORDER_FINAL_STATUSES } from '../constants';

dayjs.locale('ru');

// ----------------------------------------------------------------------

OrderGroup.propTypes = {
  createdAt: PropTypes.string,
  orders: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            variants: PropTypes.arrayOf(PropTypes.string),
          })
        ),
      })
    )
  ),
  checkOrderAction: PropTypes.func,
  isChecked: PropTypes.func,
  areAllChecked: PropTypes.func,
  checkAllAction: PropTypes.func,
};

export default function OrderGroup({
  orders,
  createdAt,
  checkOrderAction,
  isChecked,
  areAllChecked,
  checkAllAction
}) {
  return (
    <Stack>
      <Stack direction='row' justifyContent='space-between' alignItems="center">
        <Typography fontSize={16}>
          Время заказа: {dayjs(createdAt).format('HH:mm')}
        </Typography>
        {!orders.flat().flat().every(order => ORDER_FINAL_STATUSES.includes(order.status)) && (
          <Stack direction='row' alignItems="center">
            <Typography fontSize={12} color="gray">
              Выбрать все
            </Typography>
            <Checkbox
              checked={areAllChecked(orders)}
              onChange={checkAllAction(orders)}
              tabIndex={-1}
              disableRipple
              sx={{
                marginRight: '11px',
                paddingLeft: '2px'
              }}
            />
          </Stack>
        )}
        
      </Stack>
      
      <Paper variant="outlined" sx={{ position: 'relative' }}>
        <List dense={false} sx={{ paddingBottom: '0px' }}>
          {orders.map((duplicates, index) => (
            <div key={index}>
              <ListItem key={index}>
                <Stack sx={{ width: '100%' }}>
                <Grid container spacing={1} columns={14} wrap="nowrap">
                    <Grid item xs={2}>
                      <Typography align="left">{duplicates.length}x</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography align="left">{duplicates[0].menuItemName}</Typography>
                    </Grid>
                    <Grid item xs={2} pt={0.5} pl={1}>
                      <Iconify
                        icon={ORDER_STATUS_ICON[duplicates[0].status].icon}
                        width={30}
                        height={30}
                        sx={{
                          color: ORDER_STATUS_ICON[duplicates[0].status].color
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {!ORDER_FINAL_STATUSES.includes(duplicates[0].status) && (
                        <Checkbox
                          checked={isChecked(duplicates)}
                          tabIndex={-1}
                          disableRipple
                          onChange={checkOrderAction(duplicates)}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: 'gray' }}>
                      {duplicates[0].options
                        .map((option) => {
                          return `${option.name} (${option.variants.join(', ')})`;
                        })
                        .join(', ')}
                    </Typography>
                  </Box>
                </Stack>
              </ListItem>
              {(index !== orders.length - 1) && <Divider variant="middle" />}
            </div>
          ))}
        </List>
      </Paper>
    </Stack>
    
  );
}
