import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
// @mui
import {
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  Divider,
  Chip,
  Grid,
} from '@mui/material';
import Iconify from './Iconify';

import { ORDER_STATUS_ICON } from '../constants';

dayjs.locale('ru');

// ----------------------------------------------------------------------

Order.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  orderDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      amount: PropTypes.number,
      price: PropTypes.number,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          variants: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ),
};

export default function Order({ order, orderDetails }) {
  console.log(orderDetails);
  return (
    <>
      <Typography>
        Время заказа: {dayjs(order.createdAt).format('D MMM HH:mm')}
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography>Статус заказа:</Typography>
        <Chip
          icon={
            <Iconify
              icon={ORDER_STATUS_ICON[order.status].icon}
              width={18}
              height={18}
            />
          }
          label={ORDER_STATUS_ICON[order.status].meaning}
          variant="outlined"
          color={ORDER_STATUS_ICON[order.status].color}
        />
      </Stack>

      <List dense={false}>
        {orderDetails.map((detail, index) => (
          <div key={index}>
            <ListItem key={index}>
              <Stack sx={{ width: '100%' }}>
                <Grid container spacing={1} columns={14} wrap="nowrap">
                  <Grid item xs={2}>
                    <Typography align="left">{detail.amount}x</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography align="left">{detail.menuItem.name}</Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Typography variant="subtitle2" sx={{ color: 'gray' }}>
                    {detail.options
                      .map((option) => {
                        return `${option.name} (${option.variants.join(', ')})`;
                      })
                      .join(', ')}
                  </Typography>
                </Box>
              </Stack>
            </ListItem>
            <Divider variant="middle" />
          </div>
        ))}
      </List>
    </>
  );
}
