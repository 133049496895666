// import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import TokenGuard from '../guards/TokenGuard';
import LoginPage from '../pages/LoginPage';
import Tables from '../pages/Tables';
import Table from '../pages/Table';
import OrderPage from '../pages/OrderPage';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/tables',
      element: (
        <TokenGuard>
          <Tables />
        </TokenGuard>
      ),
    },
    {
      path: '/tables/:tableId',
      element: (
        <TokenGuard>
          <Table />
        </TokenGuard>
      ),
    },
    {
      path: '/orders/:orderId',
      element: (
        <TokenGuard>
          <OrderPage />
        </TokenGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
