import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

import LoginPage from '../pages/LoginPage';

export default function TokenGuard({ children }) {
  const { isLoggedIn, logout } = useContext(AuthContext);

  if (!isLoggedIn()) {
    logout();
    return <LoginPage />;
  }

  return children;
}
