import { useNavigate } from 'react-router-dom';

export default function useMenuNavigation() {
  const navigate = useNavigate();

  return {
    goToTables: () => navigate('/tables'),
    goToTable: (tableId) => navigate(`/tables/${tableId}`),
    goToOrder: (orderId) => navigate(`/orders/${orderId}`),
    goBack: () => navigate(-1),
    refresh: () => navigate(0),
    goToLogin: () => navigate('/')
  };
}
