export const ORDER_STATUS_RUSSIAN = {
  pending: 'Ожидает',
  executed: 'Исполнен',
  accepted: 'Принят',
  declined: 'Отклонён'
};

export const ORDER_STATUS = {
  pending: 'pending',
  executed: 'executed',
  accepted: 'accepted',
  declined: 'declined'
};

export const ORDER_FINAL_STATUSES = [
  ORDER_STATUS.executed,
  ORDER_STATUS.declined
];

export const ORDER_STATUS_COLOR = {
  executed: '66BFBF',
  declined: 'F45050',
  accepted: 'EAF6F6',
  pending: 'FFD24C',
};

export const ORDER_STATUS_ICON = {
  pending: {
    meaning: 'В ожидании',
    color: 'grey',
    icon: 'solar:check-read-linear',
  },
  executed: {
    meaning: 'Исполнен',
    color: 'green',
    icon: 'solar:check-read-linear',
  },
  accepted: {
    meaning: 'Принят',
    color: '#008DDA',
    icon: 'solar:check-read-linear',
  },
  declined: {
    meaning: 'Отклонён',
    color: '#A0153E',
    icon: 'material-symbols-light:close',
  },
};
