import { useState } from 'react';
import './App.css';
import { AuthProvider } from './context/AuthContext';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { setupResponseInterceptor } from './lib/axiosInstance';
import useNavigateWrapper from './hooks/useNavigateWrapper';

// ----------------------------------------------------------------------

function App() {
  const { goToLogin } = useNavigateWrapper();
  const [isLoaded, setIsLoaded] = useState(false);

  if (!isLoaded) {
    setIsLoaded(true);
    setupResponseInterceptor(goToLogin);
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
