export const TABLE_STATUS = {
  mine: 'mine',
  busy: 'busy',
  free: 'free',
  pending: 'pending',
};

export const TABLE_STATUSES_RUSSIAN = {
  mine: 'Активный',
  busy: 'Занят',
  free: 'Свободен',
  pending: 'Ожидает',
};

export const TABLE_STATUSES_COLORS = {
  mine: '66BFBF',
  busy: '19A7CE',
  free: 'EAF6F6',
  pending: 'FFD24C',
};